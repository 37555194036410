import React from "react"
import {useSelector, useDispatch} from "react-redux"
import { useCookies } from "react-cookie"
import SEO from "../components/seo/seo"
import Layout from "../components/layout/layout"
import AppBar from "../components/appBar/appBar"
import MainSection from "../components/mainSection/mainSection"
import SecondarySection from "../components/secondarySection/secondarySection"
import PageSection from "../components/pageSection/pageSection"
import Card from "../components/card/card"
import LaunchForm from "../components/launchForm/launchForm"
import OptOut from "../components/optOut/optOut"
import IFrame from "../components/iFrame/iFrame"
import Modal from "../components/modal/modal"

import * as ACTIONS from "../state/actions"

const startPlan = "<ul><li>$ 0 monthly commission for account management</li><li>$ 0 amount required to open your account</li><li>Simplified account you can receive up to $ 30,000 in deposits per month in your account</li><li>$ 60 commission for legal process document review during bank account application (one-time payment and only if your application is approved)</li><li>FREE cash withdrawals from supermarkets in minimum amounts</li><li>ATM withdrawals, commission defined by each bank</li><li>FREE Transfers between EVVA accounts</li><li>$5.5 + IVA For SPEI Transfers operating expenses</li><li>$ 149 If you require a business debit card, it is optional</li><li>$10.35 + IVA for each cash deposit at BBVA Bancomer and ScotiaBank branches</li><li>$ 30 + IVA per month If you do not use your account for 90 days, this is due to the operating expenses generated by an unused account</li></ul>";
const growPlan ="<ul><li>$ 0 monthly commission for account management</li><li>$ 0 amount required to open your account</li><li><strong>Traditional account, with no maximum deposit limit per month in your account</strong></li><li>$ 0 commission legal process document review during bank account opening (single payment and only if your application is approved)</li><li>FREE cash withdrawals from supermarkets in minimum amounts</li><li>ATM withdrawals, commission defined by each bank</li><li>FREE Transfers between EVVA accounts</li><li>$5.5 + IVA For SPEI Transfers operating expenses</li><li>1 Business debit card included, replacement for $ 100</li><li>$10.35 + IVA for each cash deposit at BBVA Bancomer and ScotiaBank branches</li><li>$ 0 commission for account inactivity</li></ul>";
const freelancePlan ="<ul><li>$ 0 monthly commission for account management</li><li>$ 0 amount required to open your account</li><li>Simplified account you can receive up to $ 30,000 in deposits per month in your account</li><li>$ 60 commission for legal process document review during bank account application (one-time payment and only if your application is approved)</li><li>FREE cash withdrawals from supermarkets in minimum amounts</li><li>ATM withdrawals, commission defined by each bank</li><li>FREE Transfers between EVVA accounts</li><li>$5.5 + IVA For SPEI Transfers operating expenses</li><li>$ 149 If you require a business debit card, it is optional</li><li>$10.35 + IVA for each cash deposit at BBVA Bancomer and ScotiaBank branches</li><li>$ 30 + IVA per month If you do not use your account for 90 days, this is due to the operating expenses generated by an unused account</li></ul>";

const tcPdf = <IFrame file="../../TC.pdf" title="Terms of use"/>;
const pcPdf = <IFrame file="../../PC.pdf" title="Privacy policy"/>;
const footerDesc = "";

const Pricing = () => {
  const appState = useSelector(state => state.appReducer);
  const dispatch = useDispatch();
  const [cookies, setCookie] = useCookies(['consent']);

  const launchFormHandler = () => {
    window.scroll({ top: 0, left: 0, behavior: "smooth" });

    const modal = {
      display: true,
      content: <LaunchForm lang={appState.lang} title="Thank you for your interest!" subtitle="Access by invitation only, register to request your access." amplitude={appState.amplitude} amplitudeClickEvent="PreSignUpCreated"/>
    };

    dispatch(ACTIONS.showModal(modal))
  };

  const termsUseHandler = () => {
    window.scroll({ top: 0, left: 0, behavior: "smooth" });
    dispatch(ACTIONS.showModal({display: true, content: tcPdf }))
  };

  const privacyPolicyHandler = () => {
    window.scroll({ top: 0, left: 0, behavior: "smooth" });
    dispatch(ACTIONS.showModal({display: true, content: pcPdf }))
  };

  const cookieHandler = () => {
    setCookie("consent", "dismiss", { path: '/' });
  };

  const closeModal = () => dispatch(ACTIONS.hideModal());

  return (
    <Layout lang={appState.lang} footerDesc={footerDesc} privacyPolicyAction={privacyPolicyHandler} termsUseHandlerAction={termsUseHandler}>
      <SEO lang={appState.lang} page="pricing" title="Plans that grow with you" description="Free business bank account and flexible plans that grow with you."/>
      {appState.modal.display && <Modal content={appState.modal.content} closeModal={closeModal}/>}
      {!cookies.consent &&
      <OptOut text="This websites uses cookies to ensure that you get the best experience on our website." privacyPolicyAction={privacyPolicyHandler} privacyPolicyLabel="See Privacy Policy" cookieAction={cookieHandler}/>
      }
      <MainSection title="Flexible plans that grow with you" position="left" image="hero-pricing.png" actionLabel="Join EVVA" action={launchFormHandler} amplitude={appState.amplitude} amplitudeClickEvent="ClickPreSignUp">
        <AppBar pathname={appState.path} />
      </MainSection>
      <PageSection maxColumns="3" title="Full plan comparison">
        <Card
          title="Start"
          description="<p>SMEs, newly established startups such as SAS, SAPI de CV, SA de CV.</p><p>Low turnover, your focus is the development of your product or service. We want to help you get started!</p>"
          actionLabel="Open your account"
          actionVariant="outlined"
          action={launchFormHandler}
          extraContent={startPlan}
          price="Free"
          image="starter-icon.png"
          imageWidth="160px"
          amplitude={appState.amplitude}
          amplitudeClickEvent="ClickPreSignUp"
          padding
          shadow
        />
        <Card
          title="Grow"
          description="<p>SMEs, Startups already established as SAS, SAPI de CV, SA de CV</p><p>You already have clients and you are billing, your business is consolidating. In this phase we want to accelerate your growth!</p>"
          actionLabel="Open your account"
          action={launchFormHandler}
          extraContent={growPlan}
          price="$175/Month"
          image="growing-icon.png"
          imageWidth="200px"
          amplitude={appState.amplitude}
          amplitudeClickEvent="ClickPreSignUp"
          padding
          shadow
          main
        />
        <Card
          title="Freelancer"
          description="<p>For individuals with business and professional activity.</p><p>You are starting your own business individually, be it consulting, your online store, Uber driver or Didi</p>"
          actionLabel="Coming soon"
          actionVariant="outlined"
          action={launchFormHandler}
          extraContent={freelancePlan}
          price="Free"
          image="freelance-icon.png"
          imageWidth="200px"
          amplitude={appState.amplitude}
          amplitudeClickEvent="ClickPreSignUpFreelancer"
          padding
          shadow
        />
      </PageSection>
      <PageSection maxColumns="2" title="Why us">
        <Card
            subtitle="Goodbye bureaucracy"
            description="<p>Say goodbye to all those complex procedures and long waiting times at the branch, with EVVA you get the speed you need to grow your business</p>"
            descriptionColor="#363636"
            image="startup-icon.png"
            horizontal
            padding
            shadow
        />
        <Card
            subtitle="Be in control"
            description="<p>With EVVA your finances are under control without stepping on a branch, stay on top of business from the home community or your cell phone</p>"
            descriptionColor="#363636"
            image="control-icon.png"
            horizontal
            padding
            shadow
        />
        <Card
            subtitle="Together we are stronger"
            description="<p>From entrepreneurs to entrepreneurs, we know how difficult it is to start and grow your business. We are here to help you and guide you all the way</p>"
            descriptionColor="#363636"
            image="group-icon.png"
            horizontal
            padding
            shadow
        />
        <Card
            subtitle="Financial Independence"
            description="<p>Our mission is to give you the financial freedom you deserve, in order to focus on what really matters, your business</p>"
            descriptionColor="#363636"
            image="freedom-icon.png"
            horizontal
            padding
            shadow
        />
      </PageSection>
      <SecondarySection
        title="Do you want to know more about us?"
        shortDescription="Let us help you to grow together."
        description="<p>Contact us directly on: <a href='mailto:info@evvafinanzas.com'>info@evvafinanzas.com<a/></p>"
        position="center"
        image="evva-contact.jpg"
        actionLabel="Get Early Access"
        action={launchFormHandler}
        amplitude={appState.amplitude}
        amplitudeClickEvent="ClickPreSignUp"
      />
    </Layout>
  )
};

export default Pricing